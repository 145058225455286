<template>
  <div class="user-info-edit">
    <van-cell v-for="item in menu" :key="item.id" :title="item.title" :is-link="item.isLink" :value="item.value" :to="item.to" @click="onCell(item)">
      <div v-if="item.id == 1" class="avatar">
        <van-image
          round
          width="1rem"
          height="1rem"
          :src="item.value"
        />
        <div v-if="userInfos.avatarStatus == 0" class="status"><img :src="icon1" alt=""></div>
      </div>
    </van-cell>
  </div>
</template>

<script>
import icon1 from '@/assets/user/avatar_status1.png'
import icon2 from '@/assets/user/avatar_status2.png'
import { mapGetters } from 'vuex'
import { getOssUrl } from '@/utils/oss'
export default {
  data() {
    return {
      menu: [],
      icon1,
      icon2
    }
  },
  computed: {
    ...mapGetters([
      'userInfos'
    ])
  },
  async mounted() {
    const avatarStatus = this.userInfos.avatarStatus
    let value = ''
    if (avatarStatus === 0) {
      value = this.userInfos.customHeadImgUrl
    } else {
      value = this.userInfos.headImgUrl
    }
    value = await getOssUrl(value)
    const nickname = this.userInfos.nickname.indexOf('*') === -1 ? this.userInfos.nickname : this.userInfos.username
    this.menu = [
      { id: 1, title: '头像', value, isLink: true, to: avatarStatus === 0 ? '' : 'userAvatarEdit' },
      { id: 2, title: '昵称', value: nickname, isLink: true, to: 'userNicknameEdit' },
      { id: 3, title: '联系电话', value: this.userInfos.username }
    ]
  },
  methods: {
    onCell(item) {
      if (item.id === 1) {
        this.$toast('头像审核中')
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.user-info-edit {
  min-height: 100%;
  padding-top: 10px;
  box-sizing: border-box;
  background: #f7f8fa;
  .van-cell {
    align-items: center;
    .van-cell__value {
      display: flex;
      justify-content: flex-end;
      .avatar {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border: 1px solid #ccc;
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        .status {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
</style>
